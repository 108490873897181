<template>
  <span :class="$style.layout">
    <Header />
    <!-- <router-view id="content" :class="$style.content" /> -->
    <router-view v-if="route && route.matched.length" id="content" :class="$style.content" />

    <Footer />
  </span>
</template>

<style lang="scss" module>
@import "./deps.scss";
@import "./_variables.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.layout {
  font-family: 'Inter', 'sans-serif';
  white-space: pre-wrap;
}

.content {
  background-color: #fff;
  width: 100%;

  h1, h2, h3, h4 {
    color: black;
    font-weight: 1000 !important;
  }
}
</style>

<script>
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import Header from '@/components/AppHeader.vue';
import Footer from '@/components/AppFooter.vue';

export default defineComponent({
  components: {
    Header,
    Footer
  },
  setup() {
    const route = useRoute();
    return { route };
  }
});
</script>


