import { createStore } from 'vuex';

import * as mutations from './mutations.js';
import * as actions from './actions.js';
import * as getters from './getters.js';

const state = {
    activeSection: {
        section: "Home"
    },
    lang: "DE",
    header: null
};

export default createStore({
    state,
    mutations,
    actions,
    getters
});
