<template>
  <div :class="$style.header" id="redhouseHeader">
    <nav class="navbar navbar-expand-lg navbar-light bg-light px-4 mt-4 text-center" style="background-color: rgb(255 255 255) !important;" :class="$style.navbarr">

        <router-link class="navbar-brand ms-4" :to="{ name: 'index' }" :class="$style.navbarBrand">
          <img :class="$style.imgResponsive" class="img-fluid" src="@/assets/img/logo.png"  title="redhouse GmbH" alt="Logo der redhouse GmbH"/>
        </router-link>
        <button class="navbar-toggler ms-auto me-1" type="button" data-bs-toggle="collapse" data-bs-target="#nav-collapse" aria-controls="nav-collapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="nav-collapse" :class="$style.coll">
          <ul class="navbar-nav ms-auto me-4">
            <li class="nav-item mx-3" :class="activeSection == 'Services' ? $style.linkActive : $style.link">
              <router-link class="nav-link" :to="{ name: 'leistungen' }">{{ findText(["leistungen", "services"]) }}</router-link>
            </li>
            <li class="nav-item mx-3" :class="activeSection == 'Career' ? $style.linkActive : $style.link">
              <router-link class="nav-link" :to="{ name: 'karriere' }">{{ findText(["karriere", "career"]) }}</router-link>
            </li>
            <li class="nav-item mx-3" :class="activeSection == 'Support' ? $style.linkActive : $style.link">
              <router-link class="nav-link" :to="{ name: 'support' }">{{ findText(["support", "support"]) }}</router-link>
            </li>
            <li class="nav-item mx-3" :class="activeSection == 'Contact' ? $style.linkActive : $style.link">
              <router-link class="nav-link" :to="{ name: 'kontakt' }">{{ findText(["kontakt", "contact"]) }}</router-link>
            </li>
            <li class="nav-item mx-3 d-flex align-items-center" :class="$style.link">
              <span @click="changeLang('EN')" :class="currentLang == 'EN' ? $style.languageActive : $style.language">en</span>
              <span :class="$style.language">/</span>
              <span @click="changeLang('DE')" :class="currentLang == 'DE' ? $style.languageActive : $style.language">de</span>
            </li>
          </ul>
        </div>
     
    </nav>
    <div :class="$style.grad"></div>
  </div>
</template>

<script>
  import EventBus from '@/eventBus';

  export default {
    name: 'AppHeader',

    data () {
      return {
        activeSection: "Home"
      }
    },
    computed: {
      currentLang () {
        return this.$store.getters.lang;
      }
    },
    methods: {
      changeLang (lang) {
        this.$store.dispatch("UPDATE_LANGUAGE", lang)
      },
      findText (translations) {
        switch (this.currentLang) {
          case "DE":
            return translations[0];
          case "EN":
            return translations[1];
          default:
            return translations[0];
        }
      }
    },
    mounted () {
      EventBus.on('changeActiveSection', (section) => {
        this.activeSection = section.section;
      });

      this.$store.dispatch("UPDATE_HEADER_HEIGHT", document.getElementById("redhouseHeader").offsetHeight);
    }
  };
</script>

<style lang="scss" module>
  @import '../_variables.scss';

  .grad {
    height: 3px !important;
    background-image: linear-gradient(rgb(255, 255, 255), rgba(255,255,255,0)) !important;
  }

  .navbarr {
    background-color: white;
  }

  .coll {
    z-index: 100 !important;
  }

  .header {
    height: 100%;
    position: sticky !important;
    top: 0;
    z-index: 10;
  }

  .imgResponsive {
    display: block;
    max-height: 20px;
    width: auto;

  }

  .navbarBrand {
    //text-shadow: 0 1px 0 rgba(255, 255, 255, .25);
  }

  .link {
    background-color: white;

    a:link, a:visited {
      color: black !important;
      font-weight: bold;
      font-size: 14pt;
      //text-shadow: 1px 1px 1px #e0e0e0;
    }
    a:hover {
      color: #E41f2b !important;
      font-size: 14pt;
      font-weight: bold;
      text-decoration: none !important;
      //text-shadow: 1px 1px 1px #e0e0e0;
    }
  }

  .linkActive {
    background-color: white;
    a:link, a:visited {
      color: #E41f2b !important;
      font-size: 14pt;
      font-weight: bold;
      // text-shadow: 1px 1px 1px #e0e0e0;
    }
    a:hover {
      color: #3f3f3f !important;
      font-size: 14pt;
      font-weight: bold;
      text-decoration: none !important;
      // text-shadow: 1px 1px 1px #e0e0e0;
    }
  }

  .language {
    color: black !important;
    font-size: 14pt !important;
    font-weight: bold;
  }

  .languageActive {
    color: #E41f2b !important;
    font-size: 14pt !important;
    font-weight: bold;
  }
</style>