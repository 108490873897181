const IndexPage = () => import(/* webpackChunkName: "views" */ '@/views/IndexPage.vue');
const ServicesPage = () => import(/* webpackChunkName: "views" */ '@/views/ServicesPage.vue');
const DataPolicyPage = () => import(/* webpackChunkName: "views" */ '@/views/DataPolicyPage.vue');
const ImpressumPage = () => import(/* webpackChunkName: "views" */ '@/views/ImpressumPage.vue');
const KarrierePage = () => import(/* webpackChunkName: "views" */ '@/views/KarrierePage.vue');
const KontaktPage = () => import(/* webpackChunkName: "views" */ '@/views/KontaktPage.vue');
const SupportPage = () => import(/* webpackChunkName: "views" */ '@/views/SupportPage.vue');
const AGBPage = () => import(/* webpackChunkName: "views" */ '@/views/AGBPage.vue');
const NotFoundPage = () => import(/* webpackChunkName: "views" */ '@/views/NotFoundPage.vue');

export default [
  {
    name: 'index',
    path: '/index',
    component: IndexPage,
  },
  {
    name: 'leistungen',  // Ensure this name matches exactly
    path: '/leistungen',
    component: ServicesPage,
  },
  {
    name: 'dataPolicy',
    path: '/data-policy',
    component: DataPolicyPage,
  },
  {
    name: 'impressum',
    path: '/impressum',
    component: ImpressumPage,
  },
  {
    name: 'karriere',
    path: '/karriere',
    component: KarrierePage,
  },
  {
    name: 'kontakt',
    path: '/kontakt',
    component: KontaktPage,
  },
  {
    name: 'support',
    path: '/support',
    component: SupportPage,
  },
  {
    name: 'agb',
    path: '/agb',
    component: AGBPage,
  },
  {
    name: 'root',
    path: '/',
    redirect: 'index',
  },
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: NotFoundPage,
  },
];
