import store from '@/store';

export const setActiveSection = async (to, _from, next) => {
  const sectionMapping = {
    index: 'Home',
    consulting: 'Services',
    implementierung: 'Services',
    optimierung: 'Services',
    projektmanagement: 'Services',
    outsourcing: 'Services',
    tisa: 'Services',
    karriere: 'Career',
    kontakt: 'Contact',
    support: 'Contact',
    impressum: 'Contact',
    dataPolicy: 'Contact',
  };

  const activeSection = sectionMapping[to.name] || 'Home'; // Default to 'Home' if no match

  store.dispatch('UPDATE_ACTIVE_SECTION', activeSection);

  return next();
};
