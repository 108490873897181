export const UPDATE_ACTIVE_SECTION = (context, activeSection) => {
    context.commit("setActiveSection", activeSection);
}

export const UPDATE_LANGUAGE = (context, lang) => {
    context.commit("setLanguage", lang);
}

export const UPDATE_HEADER_HEIGHT = (context, header) => {
    context.commit("setHeaderHeight", header);
}