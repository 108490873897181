import { createRouter, createWebHistory } from 'vue-router';
import { createMetaManager, defaultConfig, plugin as vueMetaPlugin } from 'vue-meta';

import routes from './routes.js';
import { setActiveSection } from './middleware.js';

// Create a Vue Router instance
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,  // Define routes imported from routes.js

 scrollBehavior(_from,_to , savedPosition) {
   if (savedPosition) {
  // If there's a saved position (e.g., from browser back/forward navigation), return it
     return savedPosition;
  } else {
       // Default to scroll to the top of the page
     return { left: 0, top: 0, behavior: "smooth" };
    }
  }

});

// Apply middleware
router.beforeEach(setActiveSection);

// Create a Meta Manager instance for managing meta information
const metaManager = createMetaManager(false, {
  meta: defaultConfig,
});

// Export router and metaManager
export { router, metaManager };

// Install Vue Router and Meta Manager into the Vue app
export default {
  install: (app) => {
    app.use(router);
    app.use(metaManager);
    app.use(vueMetaPlugin);
  },
};
