// deps.js

import { createMetaManager } from 'vue-meta';
import { createApp } from 'vue';
import App from './App.vue';
import Vuelidate from '@vuelidate/core';


// Create the Vue app instance
const app = createApp(App);

// Use Vue Meta with the app instance
const metaManager = createMetaManager();
app.use(metaManager);

// Use Vuelidate with the app instance
app.use(Vuelidate);



// Export the configured Vue app instance
export default app;
